import MainPage from "./components/MainPage.tsx";
import Chat from "./components/Chat.tsx";

function App() {
  return <div>
    
  <Chat/>
  <MainPage/>

</div>



  // return <div><MainPage /></div>
  
}


export default App;
