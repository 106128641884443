const resumeData = {
    
    name: "Dimitar Mladenov",
    title: "Junior Software Developer",
    email: "miti_01@abv.bg",
    linkedin: "https://www.linkedin.com/in/dimitar-mladenov-294ba9260/",
    phone: "+359 887286159",
    langauages: ["Bulgarian", "English"],


    experience: [
      {
        company: "ABAX",
        role: "Website administrator",
        startDate: "June 2022",
        endDate: "October 2022",
        description: "Developed and added new vacation products to the company website for one of the largest tour operators in Bulgaria, while also troubleshooting and resolving bugs in existing listings."
      },
      {
        company: "ISI Emerging Markets Group",
        role: "Junior Software Developer",
        startDate: "June 2023",
        endDate: "June 2024",
        description: "Developed automations using Python and JavaScript to retrieve files from multiple websites, extract the data, and input it into a database."
      }
    ],


    education: [
      {
        institution: "ППМГ Екзарх Антим I  -  Vidin, Bulgaria",
        degree: "Specialized subjects: Mathematics, Informatics, Geography and Economics, Information Technology",
        startDate: null,
        endDate: null,
        
      },
      
      {
        institution: "Sofia University St. Kliment Ohridski",
        degree: "Bachelor of Tourism ( Bussiness degree oriented towards the Tourism industry ) ",
        startDate: "2020",
        endDate: "present"
      },
    ],


    certifications: [
        "Cambridge Certificate in Advanced English C1", 
        "Python Web Basics - Softuni",  
        "Python Web Framework - Softuni"
    ],


    skills: [
        {
            hard: ["Javascript", "PostgreeSQL", "MySQL", "Linux", "OOP", 
                "Python", "Django", "Flask", "Docker", "Git", "HTML/CSS"],

            soft: ["Social", "Open minded", "Communicative", "Motivated", "Team Work", 
                "Ambition to learn new stuff", "Positive", "Agile"],
        }
    ],


    projects: [
        "SellDim - (OLX looking e-commerce website) made with Python's Django framework", 
    ]
  };
  
  export default resumeData;